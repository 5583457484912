import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const SpinnerBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
});

const Spinner = () => {
    return (
        <SpinnerBox>
            <CircularProgress size={100} />
        </SpinnerBox>
    );
};

export default Spinner;
