import { format } from 'date-fns';
import { CardSubheaderProps } from '../models/Props';
import { styled } from '@mui/system';

const SubheaderParagraph = styled('p')({
    margin: '0',
    padding: '0',
    color: 'orange',
});

const CardSubheader = (cardSubheaderProps: CardSubheaderProps) => {
    const { retrieved, status, errorMessage } = cardSubheaderProps;

    return (
        <div>
            <SubheaderParagraph>
                {/* have to do the replace because Safari has an issue with the date value
                not having the T in the formatted output https://github.com/date-fns/date-fns/issues/2130 */}
                {`retrieved ${format(
                    new Date(retrieved.replace(' ', 'T')),
                    'MM/dd'
                )} at ${format(
                    new Date(retrieved.replace(' ', 'T')),
                    'hh:mm  a'
                )}`}
            </SubheaderParagraph>
            <SubheaderParagraph>{`status ${status}`}</SubheaderParagraph>
            {status === 'error' && (
                <SubheaderParagraph>{`error ${errorMessage}`}</SubheaderParagraph>
            )}
        </div>
    );
};

export default CardSubheader;
