import { Card, CardHeader, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { DailyProps } from '../models/Props';
import CardSubheader from './CardSubheader';
import useScroll from '../hooks/useScroll';

const DailyDiv = styled('div')({
    border: 'solid 1px',
    borderColor: 'yellow',
    margin: '10px',
    padding: '10px',
});

const DailyTitle = styled('h4')({
    textAlign: 'center',
    fontSize: '24px',
    margin: '0',
    // padding: '10px',
    // border: 'solid .05px',
    // borderRadius: '25px',
    color: 'yellow',
});

const DailyContents = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '10px',
});

const DetailedParagraph = styled('p')({
    margin: '20px',
    color: '#3EF23E',
});

const ConditionsCard = styled(Card)({
    backgroundColor: '#143a63',
    color: '#66D9EF',
    borderRadius: '25px',
    padding: '5px',
});

const DailyParagraph = styled('p')({
    margin: '0',
    padding: '0',
    fontSize: '20px',
    width: '200px',
    textAlign: 'center',
    color: 'pink',
});

const DailyConditions = (daily: DailyProps) => {
    const { body, retrieved, errorMessage, status } = daily;

    const [collapse, setCollapse] = useState(false);
    const scrollHook = useScroll();

    useEffect(() => {
        if (collapse) {
            scrollHook.scrollElementToTop('daily-conditions');
        }
    }, [collapse]);

    return (
        <ConditionsCard>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        {collapse ? (
                            <ExpandLessIcon
                                htmlColor={'pink'}
                                style={{ fontSize: '48px' }}
                            />
                        ) : (
                            <ExpandMoreIcon
                                htmlColor={'pink'}
                                style={{ fontSize: '48px' }}
                            />
                        )}
                    </IconButton>
                }
                title="Daily Conditions"
                subheader={
                    <CardSubheader
                        retrieved={retrieved}
                        errorMessage={errorMessage ? errorMessage : ''}
                        status={status}
                    />
                }
                onClick={() => setCollapse(!collapse)}
            />

            <Collapse in={collapse} timeout="auto" unmountOnExit>
                {body &&
                    body.map((weatherValue) => {
                        return (
                            <DailyDiv key={weatherValue.name}>
                                <DailyTitle>{weatherValue.name}</DailyTitle>
                                <DailyContents>
                                    <DailyParagraph>
                                        {weatherValue.temperature}&#176; F
                                    </DailyParagraph>
                                    <DailyParagraph>
                                        {weatherValue.wind}
                                    </DailyParagraph>
                                </DailyContents>
                                <DetailedParagraph>
                                    {weatherValue.detailedForecast}
                                </DetailedParagraph>
                            </DailyDiv>
                        );
                    })}
            </Collapse>
        </ConditionsCard>
    );
};

export default DailyConditions;
