const useScroll = () => {
    const scrollElementToTop = (elementId: string) => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    };

    return { scrollElementToTop };
};

export default useScroll;
