import Grid from '@mui/material/Grid';
import { Card, CardHeader } from '@mui/material';
import { ConditionProps } from '../models/Props';
import { OWMConditionsResponse } from '../models/Weather';
import { styled } from '@mui/system';
import CardSubheader from './CardSubheader';
import Button from '@mui/material/Button';

const ConditionsParagraph = styled('p')({
    textAlign: 'center',
    padding: '0',
    color: '#79AD38',
    fontSize: '20px',
});

const ShortForecastHeading = styled(Grid)({
    textAlign: 'center',
    fontSize: '34px',
    padding: '10px',
});

const ImageWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});

const ForecastImage = styled('img')({
    height: '100px',
    margin: '10px',
});

const ConditionsGrid = styled(Grid)({
    margin: '0',
    padding: '0',
    border: 'solid .25px',
});

const ConditionsWrapper = styled(Grid)({
    marginTop: '5px',
});

const ConditionsCard = styled(Card)({
    backgroundColor: '#143a63',
    color: '#66D9EF',
    borderRadius: '25px',
    padding: '5px',
});

const RadarWrapper = styled(Grid)({
    fontSize: '24px',
    textAlign: 'center',
    margin: '5px',
    fontWeight: 'bold',
});

const RadarButton = styled(Button)({
    color: 'yellow',
});

const viewRadar =
    'https://radar.weather.gov/?settings=v1_eyJhZ2VuZGEiOnsiaWQiOiJ3ZWF0aGVyIiwiY2VudGVyIjpbLTc3LjYyOSwzNy42NF0sImxvY2F0aW9uIjpbLTc3LjYzLDM3LjY0M10sInpvb20iOjd9LCJhbmltYXRpbmciOmZhbHNlLCJiYXNlIjoic3RhbmRhcmQiLCJhcnRjYyI6ZmFsc2UsImNvdW50eSI6ZmFsc2UsImN3YSI6ZmFsc2UsInJmYyI6ZmFsc2UsInN0YXRlIjpmYWxzZSwibWVudSI6dHJ1ZSwic2hvcnRGdXNlZE9ubHkiOmZhbHNlLCJvcGFjaXR5Ijp7ImFsZXJ0cyI6MC44LCJsb2NhbCI6MC42LCJsb2NhbFN0YXRpb25zIjowLjgsIm5hdGlvbmFsIjowLjZ9fQ%3D%3D';

const CurrentConditions = (conditionProps: ConditionProps) => {
    const body = conditionProps.conditions.body as OWMConditionsResponse;
    const retrieved = conditionProps.conditions.retrieved;
    const errorMessage = conditionProps.conditions.errorMessage;
    const status = conditionProps.conditions.status;
    const image = conditionProps.image;
    const shortForecast = conditionProps.shortForecast;
    return (
        <ConditionsCard>
            <CardHeader
                title="Current Conditions"
                subheader={
                    <CardSubheader
                        retrieved={retrieved}
                        errorMessage={errorMessage ? errorMessage : ''}
                        status={status}
                    />
                }
            />
            <ConditionsWrapper container>
                <ConditionsGrid item xs={6}>
                    <ConditionsParagraph>
                        {Math.round(Number(body?.temperature))}
                        &#176; F
                    </ConditionsParagraph>
                </ConditionsGrid>
                <ConditionsGrid item xs={6}>
                    <ConditionsParagraph>
                        {`Humidity ${body?.humidity}%`}
                    </ConditionsParagraph>
                </ConditionsGrid>
                <ConditionsGrid item xs={6}>
                    <ConditionsParagraph>
                        {`Wind ${Math.round(Number(body?.windSpeed))} ${
                            body?.windDirection
                        }`}
                    </ConditionsParagraph>
                </ConditionsGrid>
                <ConditionsGrid item xs={6}>
                    {/* when pressure is at 30 it is normal */}
                    {/* when pressure is above 30 it is high and below 30 it is low */}
                    {/* https://weather.com/sports-recreation/fishing/news/fishing-barometer-20120328 */}
                    <ConditionsParagraph>
                        {`Pressure: ${parseFloat(body?.pressure).toFixed(2)}
                            ${
                                parseFloat(body?.pressure) === 30.0
                                    ? ''
                                    : parseFloat(body?.pressure) > 30.0
                                    ? ' H'
                                    : ' L'
                            }`}
                    </ConditionsParagraph>
                </ConditionsGrid>
                <ShortForecastHeading item xs={12}>
                    {shortForecast}
                </ShortForecastHeading>
                <Grid item xs={12}>
                    <ImageWrapper>
                        <ForecastImage src={image} alt={shortForecast} />
                    </ImageWrapper>
                </Grid>
                <RadarWrapper item xs={12}>
                    <RadarButton
                        variant="outlined"
                        style={{ color: 'yellow' }}
                        onClick={() => window.open(viewRadar, '_blank')}
                    >
                        View Radar
                    </RadarButton>
                </RadarWrapper>
            </ConditionsWrapper>
        </ConditionsCard>
    );
};

export default CurrentConditions;
