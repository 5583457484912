import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { styled } from '@mui/system';
import { WeatherReport, WeatherResponse } from '../models/Weather';
import CurrentConditions from '../components/CurrentConditions';
import DailyConditions from '../components/DailyConditions';
import HourlyConditions from '../components/HourlyConditions';
import About from '../components/About';
import Spinner from '../components/Spinner';

const HomeMain = styled('main')({
    margin: '20px',
});

const HomeHeader = styled('h1')({
    textAlign: 'center',
    color: 'pink',
    fontSize: '24px',
    marginRight: '10px',
});

const HomeSection = styled('section')({
    marginBottom: '20px',
});

const HeaderSection = styled('section')({
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
});

export const GET_ENDPOINT =
    'https://lp75jhd5vj.execute-api.us-east-1.amazonaws.com/prod/file-service';

const HomePage = () => {
    const [weather, setWeather] = useState<WeatherResponse>();

    useEffect(() => {
        axios.get(GET_ENDPOINT).then((response: AxiosResponse) => {
            setWeather({
                conditions: JSON.parse(response.data.conditions),
                daily: JSON.parse(response.data.daily),
                hourly: JSON.parse(response.data.hourly),
            });
        });
    }, []);

    return (
        <HomeMain>
            <HeaderSection>
                <HomeHeader className="home__header">
                    Goose Weather V2
                </HomeHeader>
                <img src="./goose_small.svg" />
            </HeaderSection>
            <HomeSection>
                {weather && weather.conditions ? (
                    <CurrentConditions
                        conditions={weather.conditions}
                        image={(weather.hourly.body as WeatherReport[])[0].icon}
                        shortForecast={
                            (weather.hourly.body as WeatherReport[])[0]
                                .shortForecast!
                        }
                    />
                ) : (
                    <Spinner />
                )}
            </HomeSection>
            <HomeSection id="daily-conditions">
                {weather && weather.daily ? (
                    <DailyConditions
                        body={weather.daily.body as WeatherReport[]}
                        retrieved={weather.daily.retrieved}
                        errorMessage={
                            weather.daily.errorMessage
                                ? weather.daily.errorMessage
                                : ''
                        }
                        status={weather.daily.status}
                    />
                ) : (
                    <Spinner />
                )}
            </HomeSection>
            <HomeSection id="hourly-conditions">
                {weather && weather.hourly ? (
                    <HourlyConditions
                        body={weather.hourly.body as WeatherReport[]}
                        retrieved={weather.hourly.retrieved}
                        errorMessage={
                            weather.hourly.errorMessage
                                ? weather.hourly.errorMessage
                                : ''
                        }
                        status={weather.hourly.status}
                    />
                ) : (
                    <Spinner />
                )}
            </HomeSection>
            <HomeSection>
                <About />
            </HomeSection>
        </HomeMain>
    );
};

export default HomePage;
