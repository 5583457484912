import { Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { HourlyProps } from '../models/Props';
import { styled } from '@mui/system';
import CardSubheader from './CardSubheader';
import useScroll from '../hooks/useScroll';

const ConditionsCard = styled(Card)({
    backgroundColor: '#143a63',
    color: '#66D9EF',
    borderRadius: '25px',
    padding: '5px',
});

const HourlyDiv = styled('div')({
    border: 'solid 1px',
    borderColor: 'yellow',
    margin: '10px',
    padding: '10px',
});

const CenterGrid = styled(Grid)({
    textAlign: 'center',
});

const HourlyConditions = (hourly: HourlyProps) => {
    const { body, retrieved, errorMessage, status } = hourly;

    const [collapse, setCollapse] = useState(false);
    const scrollHook = useScroll();

    useEffect(() => {
        if (collapse) {
            scrollHook.scrollElementToTop('hourly-conditions');
        }
    }, [collapse]);

    return (
        <ConditionsCard>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        {collapse ? (
                            <ExpandLessIcon
                                htmlColor={'pink'}
                                style={{ fontSize: '48px' }}
                            />
                        ) : (
                            <ExpandMoreIcon
                                htmlColor={'pink'}
                                style={{ fontSize: '48px' }}
                            />
                        )}
                    </IconButton>
                }
                title="Hourly Conditions"
                subheader={
                    <CardSubheader
                        retrieved={retrieved}
                        errorMessage={errorMessage ? errorMessage : ''}
                        status={status}
                    />
                }
                onClick={() => setCollapse(!collapse)}
            />

            <Collapse in={collapse} timeout="auto" unmountOnExit>
                {body &&
                    body.map((weatherValue) => {
                        return (
                            <HourlyDiv key={weatherValue.startTime}>
                                <Grid container>
                                    <CenterGrid xs={2}>
                                        {format(
                                            new Date(weatherValue.startTime),
                                            'hh:mm a'
                                        )}
                                    </CenterGrid>
                                    <Grid container item xs={7}>
                                        <Grid container item xs={12}>
                                            <CenterGrid item xs={6}>
                                                {weatherValue.temperature}
                                                &#176; F
                                            </CenterGrid>
                                            <CenterGrid item xs={6}>
                                                {weatherValue.wind}
                                            </CenterGrid>
                                        </Grid>
                                        <CenterGrid item xs={12}>
                                            {weatherValue.shortForecast}
                                        </CenterGrid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <img
                                            src={weatherValue.icon}
                                            alt={weatherValue.shortForecast}
                                        />
                                    </Grid>
                                </Grid>
                            </HourlyDiv>
                        );
                    })}
            </Collapse>
        </ConditionsCard>
    );
};

export default HourlyConditions;
