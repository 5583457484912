import { Card, CardHeader, Typography, CardContent } from '@mui/material';
import { styled } from '@mui/system';

const ConditionsCard = styled(Card)({
    backgroundColor: '#143a63',
    color: '#66D9EF',
    borderRadius: '25px',
    padding: '5px',
});

const AboutLink = styled('a')({
    color: 'yellow',
});

const AboutContent = styled(Typography)({
    margin: '10px',
});

const AboutTop = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const About = () => {
    return (
        <ConditionsCard>
            <CardHeader title="About Goose Weather V2" />
            <CardContent>
                <AboutTop>
                    <img src="./goose.svg" alt="goose weather logo and icon" />
                    <AboutContent variant="body1" gutterBottom>
                        {`Welcome to Goose Weather! This page shows the current
                    weather condtions for the Short Pump area of Richmond, Va.`}
                    </AboutContent>
                </AboutTop>
                <AboutContent variant="body1" gutterBottom>
                    {`Goose Weather V2 is a rewrite of a weather app originally
                    written by Andrew Evans `}
                    {
                        <AboutLink href="https://github.com/andrewevans0102/goose-weather">
                            that can be seen here
                        </AboutLink>
                    }
                    {`. This version of the app uses the `}
                    <AboutLink href="https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html">
                        AWS CDK
                    </AboutLink>
                    {` and React to
                    display radar for the Short Pump area of Richmond. It was
                    named in honor of Andrew's wife who always dreamed of being
                    a metorologist.`}
                </AboutContent>
                <AboutContent>
                    {`If you'd like to learn more about how this app works, please `}
                    <AboutLink href="https://andrewevans.dev/blog/2024-01-03-building-a-highly-performant-weather-app/">
                        check out my post on andrewevans.dev
                    </AboutLink>
                </AboutContent>
                <AboutContent>
                    {`The Goose image shown was made by `}

                    <AboutLink
                        href="https://www.flaticon.com/authors/smalllikeart"
                        title="smalllikeart"
                    >
                        smalllikeart
                    </AboutLink>
                    {` from `}
                    <AboutLink
                        href="https://www.flaticon.com/"
                        title="Flaticon"
                    >
                        www.flaticon.com
                    </AboutLink>
                    {` and is licensed by `}
                    <AboutLink
                        href="http://creativecommons.org/licenses/by/3.0/"
                        title="Creative Commons BY 3.0"
                        target="_blank"
                    >
                        CC 3.0 BY
                    </AboutLink>
                    {` .`}
                </AboutContent>
            </CardContent>
        </ConditionsCard>
    );
};

export default About;
